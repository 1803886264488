<template>
    <div class="spinner-container" v-if="isViewerLoading">
        <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>
    <OPropertiesGrid v-else :dataObject="props.dataObject" hideOkCancel>
        <template v-for="(group, index) in groupedAttributes" :key="index">
            <OPropertiesGroup :caption="$t(group.name)" :fieldName="group.name">
            <template v-for="attribute in group.attributes" :key="attribute.Field">
                <OPropertiesItem :caption="attribute.Label?.value ?? attribute.Label" v-if="props.dataObject.current.hasOwnProperty(attribute.Field)" :fieldName="attribute.Field" :required="attribute.Required">
                    <ODataLookup v-if="attribute.InputType == 'ODataLookup'" :bind="sel => {attribute.Bind(sel); saveDataObject()}" :disabled="disabledDO || attribute.Disabled || (props.dataObject.current.Sent && !props.dataObject.current.CorrespondenceRegistered)" v-model="props.dataObject.current[attribute.Field]" class="border-0 w-100" :data-object="attribute.DataObject" :columns="attribute.Columns" :whereClause="attribute.WhereClause" :multiselect="attribute.MultiSelect" :bindClear="attribute.BindClear" :class="{'border border-danger':attribute.Required  && !props.dataObject.current[attribute.Field]}"/>
                    <ODataLookup v-if="attribute.InputType == 'ODataLookupCheckBoxes'" :bind="sel => setCheckBoxValues(sel, attribute.Field, attribute.Columns[0].field)" 
                                :itemLoaded="lkpItem => checkBoxesIsSelected(lkpItem, props.dataObject.current[attribute.Field], attribute.Columns[0].field)" :disabled="disabledDO || attribute.Disabled || (props.dataObject.current.Sent && !props.dataObject.current.CorrespondenceRegistered)" 
                                :value="getCheckboxesJsonValue(props.dataObject.current[attribute.Field])" class="border-0 w-100" :data-object="attribute.DataObject" :columns="attribute.Columns" 
                                :whereClause="attribute.WhereClause" multiselect
                                :class="{'border border-danger':attribute.Required  && !props.dataObject.current[attribute.Field]}"/>
                    <OPersonsLookup v-if="attribute.InputType == 'OPersonsLookup'" :disabled="(disabledDO || attribute.Disabled) && attribute.Field != 'ObsoleteReason'" :bind="sel => {attribute.Bind(sel); saveDataObject()}" v-model="props.dataObject.current[attribute.Field]" class="border-0 w-100" :data-object="attribute.DataObject" :columns="attribute.Columns" :whereClause="attribute.WhereClause" :multiselect="attribute.MultiSelect" :bindClear="attribute.BindClear" :class="{'border border-danger':attribute.Required && !props.dataObject.current[attribute.Field]}"/>
                    <input v-if="attribute.InputType == 'input'" type="text" @blur="saveDataObject" v-model="props.dataObject.current[attribute.Field]" class="border-0 w-100" :disabled="disabledDO || attribute.Disabled || (props.dataObject.current.Sent && !props.dataObject.current.CorrespondenceRegistered)" :class="{'border border-danger': attribute.Required && !props.dataObject.current[attribute.Field]}">
                    <OTextArea v-if="attribute.InputType == 'OTextArea'" @blur="saveDataObject" :renderHTML="attribute.RenderHTML" :disabled="disabledDO || attribute.Disabled || (props.dataObject.current.Sent && !props.dataObject.current.CorrespondenceRegistered)" rows="attribute.Rows" class="border-0 w-100" v-model="props.dataObject.current[attribute.Field]" :class="{'border border-danger':attribute.Required && !props.dataObject.current[attribute.Field]}"> </OTextArea>
                    <o-date-picker v-if="attribute.InputType == 'o-date-picker'" class="border-0 w-100" :callback="saveDataObject" v-model="props.dataObject.current[attribute.Field]" :disabled="disabledDO || attribute.Disabled || (props.dataObject.current.Sent && !props.dataObject.current.CorrespondenceRegistered)" format="Short Date" :class="{'border-0 w-100':true,'border border-danger': attribute.Required && !props.dataObject.current[attribute.Field]}"></o-date-picker>
                    <div class="d-flex" v-if="attribute.InputType == 'input2x'">
                        <ONumberEditor  type="text" @blur="saveDataObject" v-model="props.dataObject.current[attribute.Field]" class="w-25 border-0" :disabled="disabledDO || attribute.Disabled || (props.dataObject.current.Sent && !props.dataObject.current.CorrespondenceRegistered)" format="%"/>
                        <input type="text" @blur="saveDataObject" v-model="props.dataObject.current[attribute.Field2]" class="w-75 ms-1 border-0" :disabled="disabledDO || attribute.Disabled || (props.dataObject.current.Sent && !props.dataObject.current.CorrespondenceRegistered)">
                    </div>
                </OPropertiesItem>
            </template>
            </OPropertiesGroup>
        </template>
         <OPropertiesGroup :caption="$t('Properties')" :fieldName="$t('Properties')">
            <OPropertiesForm :row="props.dataObject.current" viewName="aviw_Correspondence_Items" hideLoadingIndicator :editMode="true">
                <template #Editor="{properties}"/>
            </OPropertiesForm>
        </OPropertiesGroup>
        <ODataLookup  
            :data-object="local_dsPropertiesLkp"
            :height="400"
            :bind="sel => handleAddProperty(sel)"
            :noClear="true">
                <template #target="scope" @click="scope.open">
                    <h2 class="mb-0 mt-2 ms-1">
                        <button class="btn btn-sm btn-link py-0 mb-1 ps-0"  :ref="scope.target" style="font-size: 16px;"><i class="bi bi-plus-circle me-1"></i>{{$t("Add Property")}}</button>
                    </h2>
                </template>
                <OColumn name="PropertyName" width="400"></OColumn>
        </ODataLookup>
    </OPropertiesGrid>
</template>

<script setup>
import * as DataObjects from 'correspondence.vue.components.DBObjectDefinitions.ts';
import { getOrCreateDataObject } from 'o365-dataobject';
import { ref, computed } from 'vue';
import { OPersonsLookup } from "o365-system-lookups";
import { OPropertiesGrid, OPropertiesItem, OPropertiesGroup }  from 'o365-data-components';
import { OPropertiesForm } from 'o365-data-properties';

const props = defineProps({
    dataObject: Object
})

function saveDataObject(){
    props.dataObject.save();
}

const handleAddProperty = (pSel) => {
    local_dsCorrProperties.createNew({PropertyName:pSel.PropertyName});
    local_dsCorrProperties.save().then(() => props.dataObject.load());
    console.log(pSel)
}
// attributes should be reloaded on change of fields that are part of whereclause on lookup. Or implement smarter whereClause approach (computed)

const requiredFields = ref("");
//const hiddenFields = ref("");
const isViewerLoading = ref(true);

const local_dsClassificationCode = getOrCreateDataObject(DataObjects.local_dsClassificationCode),
local_dsOrigCodesLkp =  getOrCreateDataObject(DataObjects.local_dsOrigCodesLkp),
local_dsReceiverCodesLkp =  getOrCreateDataObject(DataObjects.local_dsReceiverCodesLkp),
local_dsTopics = getOrCreateDataObject(DataObjects.local_dsTopics),
local_dsProjectCodes = getOrCreateDataObject(DataObjects.local_dsProjectCodes),
local_dsDisciplines = getOrCreateDataObject(DataObjects.local_dsDisciplines),
local_dsMainAreas = getOrCreateDataObject(DataObjects.local_dsMainAreas),
local_dsErrors = getOrCreateDataObject({
    id: 'local_dsErrors',
    viewName: 'atbv_Correspondence_ItemsValidationErrors',
    appId: props.dataObject.appId,
    maxRecords: -1,
    whereClause: "",
    loadRecents: true,
    distinctRows: true,
    masterDataObject_ID: props.dataObject.id,
    masterDetailDefinition: [{
        detailField: "Correspondence_ID",
        masterField: "ID",
        operator: "equals"
    }],
    fields:
        [{name: "Error", type: "string"},
        {name: "FieldName", type: "string" }]
}),

local_dsCorrProperties = getOrCreateDataObject({
    id: 'local_dsCorrProperties',
    viewName: 'atbv_Correspondence_ItemsProperties',
    appId: props.dataObject.appId,
    allowInsert:true,
    allowUpdate:true,
    allowDelete:true,
    maxRecords: -1,
    whereClause: "",
    loadRecents: true,
    distinctRows: true,
    masterDataObject_ID: props.dataObject.id,
    masterDetailDefinition: [{
        detailField: "Correspondence_ID",
        masterField: "ID",
        operator: "equals"
    }],
    fields:
        [{name: "PropertyName", type: "string"},
        { name: "Value", type: "string" },
        { name: "DateValue", type: "date" },
        { name: "DateTimeValue", type: "datetime" },
        { name: "IntValue", type: "number" },]
}),

local_dsPropertiesLkp = getOrCreateDataObject({
    id: 'local_dsPropertiesLkp',
    viewName: 'aviw_Correspondence_PropertiesLookup',
    appId: props.dataObject.appId,
    maxRecords: -1,
    whereClause: "",
    loadRecents: true,
    distinctRows: true,
    fields:
        [{name: "Name", type: "string"},
        {name: "PropertyName", type: "string"},
        {name: "PropertyNameTranslated", type: "string"},
        { name: "DataType", type: "string" },
        { name: "Description", type: "date" },
        { name: "Title", type: "datetime" },]
})

const attributes = ref([]);
const setDefaultAttributes = () => {
    attributes.value = [
        
        {
            Label: $t('Originator Code'),
            Field: "OriginatorCode",
            RequiredField: "OriginatorCode",
            InputType: 'ODataLookup',
            DataObject: local_dsOrigCodesLkp,
            Bind: (sel) => {props.dataObject.current.OriginatorCode_ID = sel.ID, props.dataObject.current.OriginatorCode = sel.NameAndTitle;},
            Columns: [
                {field:'NameAndTitle', headerName:'Name And Title', width:300}
            ],
            WhereClause: `${props.dataObject.current.OrgUnit_ID} = PublishedToOrgUnit_ID AND Closed IS NULL`,
            Group:"Common"
        },
        {
            Label: $t('Receiver Code'),
            Field: "ReceiverCode",
            RequiredField: "ReceiverCode",
            InputType: 'ODataLookup',
            DataObject: local_dsReceiverCodesLkp,
            Bind: (sel) => {props.dataObject.current.ReceiverCode_ID = sel.ID, props.dataObject.current.ReceiverCode = sel.NameAndTitle;},
            Columns: [
                {field:'NameAndTitle', headerName:'Name And Title', width:300}
            ],
            WhereClause: (props.dataObject.current.CorrespondenceRegistered) ? `${props.dataObject.current.OrgUnit_ID} = PublishedToOrgUnit_ID AND Closed IS NULL` : `${props.dataObject.current.ReceiverOrgUnit_ID} = PublishedToOrgUnit_ID AND Closed IS NULL`,
            Group:"Common"
        },
        {
            Label: $t('Topic'),
            Field: "Topic",
            RequiredField: "Topic",
            InputType: 'ODataLookup',
            DataObject: local_dsTopics,
            Bind: (sel) => {props.dataObject.current.Topic_ID = sel.ID, props.dataObject.current.Topic = sel.NameAndDescription;},
            Columns: [
                {field:'NameAndDescription', headerName:'Name And Description', width:300}
            ],
            WhereClause: `${props.dataObject.current.OrgUnit_ID} = PublishedToOrgUnit_ID`,
            Group:"Common"
        },
        {
            Label: $t('Project Code'),
            Field: "ProjectCode",
            RequiredField: "ProjectCode",
            InputType: 'ODataLookup',
            DataObject: local_dsProjectCodes,
            Bind: (sel) => {props.dataObject.current.ProjectCode_ID = sel.ID, props.dataObject.current.ProjectCode = sel.Name;},
            Columns: [
                {field:'ID', headerName:'ID', width:100},
                {field:'Name', headerName:'Name', width:200},
                {field:'OrgUnit', headerName:'OrgUnit', width:200},
            ],
            WhereClause: `${props.dataObject.current.OrgUnit_ID} = PublishedToOrgUnit_ID`,
            Group:"Common"
        },
        {
            Label: $t('Discipline'),
            Field: "Discipline",
            RequiredField: "Discipline",
            InputType: 'ODataLookup',
            DataObject: local_dsDisciplines,
            Bind: (sel) => {props.dataObject.current.Discipline_ID = sel.ID, props.dataObject.current.DisciplineNameAndTitle = sel.NameAndTitle;},
            Columns: [
                {field:'ID', headerName:'ID', width:100},
                {field:'Name', headerName:'Name', width:200},
                {field:'Title', headerName:'Title', width:200},
            ],
            WhereClause: `${props.dataObject.current.OrgUnit_ID} = PublishedToOrgUnit_ID AND Closed IS NULL`,
            Group:"Common"
        },
        {
            Label: $t('Classification Code'),
            Field: "Classification",
            RequiredField: "Classification",
            InputType: 'ODataLookup',
            DataObject: local_dsClassificationCode,
            Bind: (sel) => {props.dataObject.current.Classification_ID = sel.ID, props.dataObject.current.Classification = sel.NameAndTitle},
            Columns: [
                {field:'ID', headerName:'ID', width:100},
                {field:'Name', headerName:'Name', width:200},
                {field:'Title', headerName:'Title', width:200}
            ],
            WhereClause: `${props.dataObject.current.OrgUnit_ID} = PublishedToOrgUnit_ID AND Closed IS NULL`,
            Group:"Common"
        },
        {
            Label: $t('Main Area'),
            Field: "MainArea",
            RequiredField: "MainArea",
            InputType: 'ODataLookup',
            DataObject: local_dsMainAreas,
            Bind: (sel) => {props.dataObject.current.MainArea_ID = sel.ID, props.dataObject.current.MainArea = sel.Name;},
            Columns: [
                {field:'Name', headerName:'Name', width:200},
                {field:'Title', headerName:'Title', width:200},
            ],
            WhereClause: `${props.dataObject.current.OrgUnit_ID} = PublishedToOrgUnit_ID`,
            Group:"Common"
        },
    ]
}

setDefaultAttributes();

const attributeGroups = [
    {Name: "Required",SortOrder:1, Collapsed:false},
    {Name: "Common",SortOrder:2, Collapsed:false},
    {Name: "Asset",SortOrder:3, Collapsed:true},
]


const groupedAttributes = computed(() => {
    // Group the attributes by the 'group' property
    const groups = attributes.value.reduce((groups, attribute) => {
        const groupName = attribute.Group || 'Ungrouped';
        if (!groups[groupName]) {
            groups[groupName] = {
                name: groupName,
                attributes: [],
                sortIndex:attributeGroups.find((group) => group.Name === groupName).SortOrder,
                collapsed:attributeGroups.find((group) => group.Name === groupName).Collapsed
            };
        }
        groups[groupName].attributes.push(attribute);
        return groups;
    }, {});

    // Convert the groups object to an array
    return Object.values(groups).sort((a, b) => a.sortIndex - b.sortIndex);
});

const disabledDO = computed(() => {
    return (props.dataObject.current.Deleted != null || props.dataObject.current.Obsolete != null || props.dataObject.current.Released)
});

const getRequiredFields = () => {
    requiredFields.value = {}
    local_dsErrors.data.map((row)=>{
        if(!props.dataObject.current[row.FieldName]){
            requiredFields.value[row.FieldName] = true;
            requiredFields.value[row.FieldName+'Error'] = row.Error;
        }
    })
}

props.dataObject.on('AfterSave',(pFieldsUpdated) => {
    const updateAttributes = computed(() => {
        if(pFieldsUpdated.values.hasOwnProperty('DocumentType_ID') || pFieldsUpdated.values.hasOwnProperty('OrgUnit_ID'))
            return true;
        else
            return false;
    });

    local_dsErrors.load().then(()=>{
        if(updateAttributes.value){
            getRequiredFields();
            setDefaultAttributes();
            sortAttributes();
        }
    });
})

local_dsErrors.on('DataLoaded', () => {
    getRequiredFields();
})

const sortAttributes = () =>{
    attributes.value.sort((a,b) => {
        if(requiredFields.value[a.RequiredField ?? a.Field] && !requiredFields.value[b.RequiredField ?? b.Field]){
            a.Required = true;
            a.Group = "Required"
            return -1;
        }
        if(!requiredFields.value[a.RequiredField ?? a.Field] && requiredFields.value[b.RequiredField ?? b.Field]){
            b.Required = true;
            b.Group = "Required"
            return 1;
        }
        if(requiredFields.value[a.RequiredField ?? a.Field] &&  requiredFields.value[b.RequiredField ?? b.Field]){
            a.Required = true;
            b.Required = true;
            a.Group = "Required"
            b.Group = "Required"
        }
        return 0;
    })

    attributes.value.sort((a,b) => {
        if(!requiredFields.value[a.RequiredField ?? a.Field] && !props.dataObject.current[a.Field] && props.dataObject.current[b.Field]){
            b.IsSet = true;
            return 1;
        }
        if(!requiredFields.value[b.RequiredField ?? b.Field] && !props.dataObject.current[b.Field] && props.dataObject.current[a.Field]){
            a.IsSet = true;
            return -1;
        }
        if(props.dataObject.current[a.Field] && props.dataObject.current[b.Field]){
            a.IsSet = true;
            b.IsSet = true;
        }
        return 0;
    })

    isViewerLoading.value = false
}

local_dsErrors.load().then(() => {
    sortAttributes();
});

// checkboxes lookup helpers (parse/save in json)
const getCheckboxesJsonValue = (pJson) => {
    if (!pJson){
        return ''
    }
    try {
        return JSON.parse(pJson).join(', ');
    } catch (e) {
        return pJson;
    }  
}
function checkBoxesIsSelected(plkpItem, pJson, pField){
    if (!pJson){
        return
    }
    try {
        plkpItem.isSelected = JSON.parse(pJson).includes(plkpItem[pField]);
    } catch (e) {
        plkpItem.isSelected = pJson.split(', ').includes(plkpItem[pField]);
    } 
}
function setCheckBoxValues(pSel, pColumn, pField){
    if(pSel && pSel.length){
        props.dataObject.current[pColumn] = JSON.stringify(pSel.map(item => item[pField]));
    }else{
        props.dataObject.current[pColumn] = null;
    }
    saveDataObject();
}

const addCorrProp = (sel) => {
    debugger;
    /*let vPropData;
    switch(sel.DataType){
        case "string":
            let vPropData = {PropertyName:sel.Name}
        break;
        case "bool":
        break;
        case "number":
        break;
        case "date":
        break;
        case "datetime":
        break;
    }*/
    local_dsCorrProperties.createNew({PropertyName:sel.Name})
    local_dsCorrProperties.save().then(() => {local_dsCorrProperties.load()})

}

</script>

<style scoped>
.spinner-container{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.spinner-border {
  width: 5rem;
  height: 5rem;
}

.overlay__inner{
    display:none;
}

</style>